import dayjs from '@troon/dayjs';
// eslint-disable-next-line import/no-unresolved
import { createAsync, useLocation, useParams } from '@solidjs/router';
import { BreadcrumbItem, Breadcrumbs, Heading, Picture, Tag, Container, HorizontalRule } from '@troon/ui';
import { ErrorBoundary, Show, Suspense } from 'solid-js';
import { Meta, Title } from '@solidjs/meta';
import { getConfigValue } from '../../modules/config';
import { NotFoundContent } from '../../partials/404';
import { createContentfulRequest } from '../content/[model]/_client';
import { RichText, richTextToPlain } from '../../components/rich-text';

export default function PressReleasePage() {
	const loc = useLocation();
	const params = useParams<{ release: string }>();
	const data = createAsync(async () => getPressRelease(params.release), { deferStream: true });

	return (
		<ErrorBoundary fallback={<NotFoundContent />}>
			<Suspense>
				<Show when={data()?.fields}>
					{(data) => (
						<>
							<Title>{data().title} | Press Release | Troon</Title>
							<Meta
								name="description"
								content={data().content ? `${richTextToPlain(data().content!).slice(0, 154)}…` : undefined}
							/>
							<Meta name="og:title" content={`${data().title} | Troon`} />
							<Meta name="og:url" content={`https://${getConfigValue('HOST')}/press-releases/${data()?.slug}`} />
							<Meta name="og:type" content="article" />
							<Meta name="article:published_time" content={dayjs(data()?.publishDate).toISOString()} />
							<Meta name="og:image" content={`https://${data()?.heroImage?.fields.file?.url}`} />
						</>
					)}
				</Show>
			</Suspense>
			<Container class="pb-16 pt-4">
				<Suspense>
					<Show when={data()?.fields}>
						{(data) => (
							<Breadcrumbs>
								<BreadcrumbItem href="/press-releases">Press Releases</BreadcrumbItem>
								<BreadcrumbItem href={loc.pathname}>{data().title}</BreadcrumbItem>
							</Breadcrumbs>
						)}
					</Show>
				</Suspense>
			</Container>
			<Container size="small" class="flex flex-col gap-8">
				<Tag class="text-sm">Press release</Tag>
				<Heading as="h1">
					<Suspense>
						<Show when={data()?.fields}>{(data) => <>{data().title}</>}</Show>
					</Suspense>
				</Heading>
				<time class="text-lg">
					<Suspense>
						<Show when={data()?.fields}>
							{(data) => dayjs(data().publishDate, 'America/Phoenix').format('MMMM D, YYYY')}
						</Show>
					</Suspense>
				</time>
			</Container>
			<div class="container mx-auto my-8 max-w-screen-lg sm:px-6 md:px-12">
				<Suspense>
					<Show when={data()?.fields.heroImage?.fields}>
						{(image) => (
							<Picture
								preload
								src={`https://${image().file?.url}`}
								width={1024}
								height={576}
								sizes="(min-width: 1440px) 50vw, 95vw"
								class="w-full sm:rounded"
								alt={image().description ?? image().title ?? ''}
								crop="face"
							/>
						)}
					</Show>
				</Suspense>
			</div>
			<Suspense>
				<Show when={data()?.fields}>
					{(content) => (
						<Container size="small">
							<RichText document={content().content!} />
							<Show when={content().footer?.fields}>
								{(footer) => (
									<>
										<HorizontalRule />
										<RichText document={footer().content} />
									</>
								)}
							</Show>
						</Container>
					)}
				</Show>
			</Suspense>
		</ErrorBoundary>
	);
}

const getPressRelease = createContentfulRequest('press-release');
